<template>
  <main>
    <Preloader v-if="!dev" />
    <!-- BANNER -->
    <div v-else>
      <!-- GENERAL CONTAINER -->
      <section class="ftco-section contact-section bg-light">
        <div class="container">
          <div class="row">
            <!-- Detail Section -->
            <div class="col-lg-8 bg-white p-5">
              
              <!-- CAROUSEL -->
              <DetailCarousel :items="dev.images" />

              <!-- SECCION A -->
                <div class="border-bottom pb-3">
                  <h2 v-if="dev.developmentNameEs != 0">
                    {{ dev.developmentNameEs }}
                  </h2>
                  <span class="grey" v-if=" dev.address !='0' || dev.state !='0'">
                    <p>
                      <i class="fa fa-map-marker-alt"></i> 
                      {{dev.address}}
                    </p>
                    <p class="color-page">
                      {{dev.state}}
                    </p>
                  </span>
                </div>

              <div class="row mt-3 border-bottom">
                <div class="col-md-5">
                  <h5>
                    <b> {{$t("pages.development_detail.price_from")}}:  </b> 
                  </h5>
                  <strong class="text-success h1 mb-3">
                    {{format}}
                  </strong>
                </div>
                <!-- Best Information & Prices -->
                <div class="col-md-7">
                  <ul class="row justify-content-center devs-ul ">
                    <li v-if="dev.parking!=0" class="col-lg-4">
                        <span class="dev-specs" >
                            {{$t("pages.property_detail.feature_parking")}}
                        </span>
                        <span class="devs-list">
                           <i class="fas fa-car"></i> <b>{{dev.pkFrom}} {{(dev.pkTo)?`- ${dev.pkTo}`:''}}</b>
                        </span>
                      </li>
                      <li v-if="dev.bedFrom||dev.bedTo" class="col-lg-4">
                        <span class="dev-specs" >
                          {{$t("pages.property_detail.feature_bedrooms")}}
                        </span>
                        <span class="devs-list" >
                          <i class="fa fa-bed"></i> <b>{{ dev.bedFrom }} {{(dev.bedTo)?`- ${dev.bedTo}`:''}} </b>
                        </span>
                      </li>

                      <li v-if="dev.bathFrom||dev.bathTo" class="col-lg-4">
                        <span class="dev-specs" >
                          {{$t("pages.property_detail.feature_bathrooms")}}
                        </span>
                        <span class="devs-list">
                          <i class="fa fa-bath"></i> <b>{{ dev.bathFrom }} {{(dev.bathTo)?`- ${dev.bathTo}`:''}}</b>
                        </span>
                      </li>
                  </ul>
                </div>
              </div>

              <div class="mt-3 border-bottom">
                <ul class="row justify-content-center devs-ul ">
                      <li v-if="dev.folio" class="col-lg-4 text-center">
                        <span class="dev-specs" >
                          {{$t("pages.property_detail.folio")}}
                        </span>
                        <span>
                          <i class="fa fa-hashtag"></i> <b>{{ dev.folio }}</b>
                        </span>
                      </li>
                      <li v-if="dev.m2cFrom||dev.m2cTo" class="col-lg-4  text-center">
                        <span class="dev-specs" >
                          {{$t("pages.property_detail.feature_area_cons")}}
                        </span>
                        <span>
                         <i class="fa fa-arrows-alt" aria-hidden="true"></i> <b>
                            {{ dev.m2cFrom }} {{(dev.m2cTo)?`- ${dev.m2cTo}`:''}} m <sup>2</sup>
                          </b>
                        </span>                      
                      </li>
                      <li v-if="dev.bathFrom||dev.bathTo" class="col-lg-4 text-center">
                        <span class="dev-specs" >
                          {{$t("pages.development_detail.units_aviable")}}
                        </span>
                        <span>
                          <i class="fa fa-hashtag"></i> <b>{{ dev.unitsAviable }}</b>
                        </span>
                      </li>
                  </ul>

              </div>

              <div class="mt-5 mb-5 text-center">
                <button type="button" @click="goTo('master-plan')" class="go-masterplan-button btn btn-primary py-3 px-5 mb-2 rounded-2"> 
                  {{$t("pages.development_detail.masterplan_title")}}
                </button>
              </div>

              <!-- Description -->
              <section>
                <h2 class="text-center mb-5">
                   {{$t("pages.property_detail.description_prop")}}
                </h2>
                <div>
                  <h5>Español</h5>
                  <p>
                    <pre>{{ dev.descriptionEs }}</pre>
                  </p>
                </div>
                <div v-if="dev.descriptionEn">
                  <hr>
                  <h5 >English</h5>
                  <p>
                    <pre>{{ dev.descriptionEn }}</pre>
                  </p>
                </div>
              </section>

              
              <!-- Amenities -->
              <section class="border-top py-4" v-if=" dev.amenities || dev.services || dev.maintenance_includes">
                <h2 class="text-center">{{$t("pages.property_detail.amenities_prop")}}</h2>
                <div class="container mb-3" v-if="dev.amenities">
                  <div class="row mb-3">
                    <h4 class="h4 text-black">{{$t("pages.property_detail.amenities_interior")}}</h4>
                  </div>
                  <div class="row">
                    <p class="amenidades col-md-4" v-for="(item,i) in dev.amenities" :index="i" :key=i> 
                      <i class="icon-check" aria-hidden="true"></i>
                      {{item}}
                    </p>                   
                  </div>
                </div>

                <div class="container mb-3" v-if="dev.maintenance_includes">
                  <div class="row mb-3">
                    <h4 class="h4 text-black"> {{$t("pages.development_detail.maintenance_includes")}}</h4>
                  </div>
                  <div class="row">
                    <p class="amenidades col-md-4" v-for="(item,i) in dev.maintenance_includes" :index="i" :key=i> 
                      <i class="icon-check" aria-hidden="true"></i>
                      {{item}}
                    </p>
                  </div>
                </div>

                <div class="container mb-3" v-if="dev.services">
                  <div class="row mb-3">
                    <h4 class="h4 text-black"> {{$t("pages.property_detail.services_prop")}}</h4>
                  </div>
                  <div class="row">
                    <p class="amenidades col-md-4" v-for="(item,i) in  dev.services" :index="i" :key=i> 
                      <i class="icon-check" aria-hidden="true"></i>
                      {{item}}
                    </p>
                  </div>
                </div>
              </section>
            </div>

            <!-- Widget Section -->
            <div class="col-lg-4">
              <!-- Main Contact Information -->
              <div class="ftco-section contact-section bg-white">
                <div class="bg-white" style="text-align: center">
                   <img
                    class="img"
                    :src="
                      dev.agent.image ? 'https://agent.immosystem.com.mx/'+dev.agent.image : '/images/avatar.png'
                    "
                    :alt="`${dev.agent.name}-img`"
                  />
                  <hr />
                  <p class="mb-0">
                    <!-- <b class="texto2"><i class="icon-envelope-open texto2"></i> :</b>-->
                    <a class="contact-links"> {{ dev.agent.name }}</a>
                  </p>
                  <p class="mb-0">
                    <b class="texto2"
                      ><i class="icon-phone texto2"></i> /
                      <i class="icon-whatsapp"></i> :</b
                    >
                    <a
                      class="contact-links"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Contactar en WhatsApp"
                      target="_blank"
                      :href="
                        `https://api.whatsapp.com/send?text=${$t(
                          'pages.property_detail.share_whats_message_agent'
                        )}: ${url}&phone=52${dev.agent.cellphone}`
                      "
                    >
                      {{ dev.agent.cellphone }}
                    </a>
                  </p>
                  <p class="mb-4 ">
                    <b class="texto2"
                      ><i class="icon-envelope-open texto2"></i> :</b
                    >
                    <a class="contact-links"> {{ dev.agent.email }}</a>
                  </p>
                </div>
                <!-- Contact Form -->
                <div class="bg-white rounded ">
                  <ContactForm />
                </div>
              </div>

              <!-- Download Files -->
              <div
                class=" ftco-animate mb-5 mt-2 fadeInUp ftco-animated bg-white"
              >
                <section class="p-5">
                  <div class="row text-center border-bottom mb-3">
                    <h2 class="text-center h2-widget">{{ $t("pages.property_detail.share_title") }}</h2>
                  </div>
                  <div>
                  </div>
                  <div>
                    <h6 class=" text-black">  Flyers Español </h6>
                    <ul class="buttons-content banner row justify-content-center">
                        <li v-if="dev.flyer.flyerEs4" class="banners col-md-8">
                        <a class="banner-button" target="_blank" :href="dev.flyer.flyerEs4">
                            <i class="fa fa-file-image-o"></i> 4 Fotos
                        </a>
                        </li>
                        <li v-if="dev.flyer.flyerEs10" class="banners col-md-8">
                        <a class="banner-button" target="_blank" :href="dev.flyer.flyerEs10">
                            <i class="fa fa-file-image-o"></i> 10 Fotos
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div>
                        <h6 class="text-black"> English Flyers</h6>
                        <ul class="buttons-content banner row justify-content-center"> 
                            <li v-if="dev.flyer.flyerEn4" class="banners col-md-8 ">
                            <a class="banner-button" target="_blank" :href="dev.flyer.flyerEn4">
                                <i class="fa fa-file-pdf-o"></i> 4 Images
                            </a>
                            </li>
                            <li v-if="dev.flyer.flyerEn10" class="banners col-md-8 ">
                            <a class="banner-button" target="_blank" :href="dev.flyer.flyerEn10">
                                <i class="fa fa-file-pdf-o"></i> 10 Images
                            </a>
                            </li>
                        </ul>
                    </div>
                </section>
              </div>

              <div class="properties-single ftco-animate mb-5 mt-4 fadeInUp ftco-animated bg-white ">
                <section class="p-5 text-center">
                  <div class="border-bottom mb-3">
                    <h2 class="h4 text-black">
                      {{$t("pages.property_detail.share_prop")}}
                     </h2>
                  </div>
                  <div class="row py-3 site-foote justify-content-center">
                    <a
                      :href="
                        `https://www.facebook.com/sharer/sharer.php?u=${url}`
                      "
                      target="_blank"
                      class="pl-3 pr-3"
                    >
                      <span class="icon-facebook"></span>
                    </a>
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Compartir por Twitter"
                      :href="`https://twitter.com/home?status=${url}`"
                      target="_blank"
                      class="pl-3 pr-3"
                    >
                      <span class="icon-twitter"></span>
                    </a>
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Compartir por WhatsApp"
                      :href="
                        `https://api.whatsapp.com/send?text=${$t(
                          'pages.property_detail.share_whats_message_public'
                        )}: ${url}`
                      "
                      class="pl-3 pr-3"
                      target="_blank"
                    >
                      <span class="icon-whatsapp"></span>
                    </a>
                  </div>
                </section>
              </div>

              <!-- Map -->
              <div v-if="dev.latitude|| dev.longitude" class=" properties-single ftco-animate mb-5 mt-4 fadeInUp ftco-animated bg-white">
                 <div class="p-3 col-md-12 no-gutters">
                  <div class="col-12">
                    <h2 class="text-center mb-5">
                      {{$t("pages.development_detail.map_title")}}
                    </h2>
                  </div>
                  <div class="col-12">
                    <GoogleMap :mapLat="dev.latitude" :mapLng="dev.longitude" />
                  </div>
                </div>

              </div>

              <!-- Video -->
              <div v-if="dev.video"  class="properties-single ftco-animate mb-5 mt-4 fadeInUp ftco-animated bg-white">
                 <div class="col-md-12 no-gutters p-3">
                  <div class="col-12">
                    <h4 class="text-black mb-3"> 
                      Video
                    </h4>
                  </div>
                  <div class="col-12">
                    <iframe width="100%" height="315" :src="dev.video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <!-- Mapper area -->
          <section id="master-plan" class="row ftco-section contact-section bg-light">
            <div class="col-md-12 ">
                <div class="bg-white">
                  <!-- Mapper -->
                  <section class="row  master-plan" v-if="dev.blueprints">
                      <div class="col-md-12 text-center">
                        <div class="site-section-title">
                          <h2>{{$t("pages.development_detail.masterplan_title")}}</h2>
                        </div>
                        <div class="mt-5 image-master-plan">
                          <a  class="example-image-link animated-link-dark" :href="dev.masterplan[0].image" data-lightbox="example-set" :data-title="dev.developmentNameEs">
                              <img class="cover" :src="dev.masterplan[0].image" :alt="`masterplan-${dev.developmentNameEs}`" srcset="">
                          </a>
                        </div>
                        <div class="mt-5 mb-3">
                          <button type="button" @click="goToSection()" class="btn btn-primary py-3 px-5 mb-2 rounded-2 go-masterplan-button"> {{$t("pages.development_detail.masterplan_section_button")}} </button>
                        </div>
                      </div>
                    </section>
                    <!--section class="row mb-5" v-if="dev.blueprints">
                      <div class=" col-md-12 no-gutters">
                        <div class="row">
                          <h4 class="h4 text-black"> 
                            {{$t("pages.development_detail.mapper_title")}}
                          </h4>
                        </div>
                        <div class="row references">
                          <div class="col">
                            <div class="color-reference status-1"></div>
                            <span>Disponible</span>
                          </div>
                          <div class="col">
                            <div class="color-reference status-2"></div>
                            <span>Reservado</span>
                          </div>
                          <div class="col">
                            <div class="color-reference status-3"></div>
                            <span>No Disponible</span>
                          </div>
                        </div>

                        <div >
                          <ul class="nav nav-tabs mt-4" id="mapperTab" role="tablist">
                            <li class="nav-item" v-for="(mapSection,i) in dev.blueprints" :key="i" :index="mapSection[i]">
                              <a :class="['nav-link', {'active':i==0} ]" :id="`section-tab-${i}`" data-toggle="tab" :href="`#section${i}`" role="tab" aria-controls="home" aria-selected="true">Sección {{i+1}}</a>
                            </li>
                          </ul>

                          <div class="tab-content mt-3" id="mapperTabContent">
                            <div v-for="(mapSection,i) in dev.blueprints" :key="i" :index="mapSection[i]" :id="'section'+i" :class="['tab-pane fade', {'show active':i==0}]" role="tabpanel" :aria-labelledby="`section-tab-${i}`">
                              <Mapper :mapper="mapSection"/> 
                              <div id="myresult" class="img-zoom-result"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section-->
                </div>
            </div>
          </section>
        </div>
      </section>

      


    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import GoogleMap from "@/components/layout/GoogleMap.vue";
import DetailCarousel from "@/components/carousel/DetailCarousel.vue";
import Mapper from "@/components/developments/Mapper.vue";
export default {
  components: {
    Banner,
    Preloader,
    ContactForm,
    GoogleMap,
    DetailCarousel,
    Mapper
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      dev: "_getDevelopment",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
    format(val) {
      let value = val.dev.priceFrom;
      //Formateo de decimales
      let decimalPrice = value.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = value.indexOf("MXN");
      let currentPriceU = value.indexOf("USD");

      // Precio formateado value.substr(currentPrice)
      let formatPrice = value.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += value.substr(currentPriceM);
      } else {
        formatPrice += value.substr(currentPriceU);
      }
      return '$'+formatPrice.slice(1);
    },
  },
  created() {
    this.folio = this.$route.params.folio;

    let data = {
      folio: this.folio,
    };
    this.$store.dispatch("getDevelopment", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;
  },
  methods:{
    goTo(ref){ 
      document.getElementById(ref).scrollIntoView({block: "nearest", behavior: "smooth"});
    },
    goToSection(){
      let folio=this.folio;
      this.$router.push({name:'DevelopmentSections',params: {folio}})
    }
  }
};
</script>


<style scoped>
.price {
  background-color: #9cd7d3c5;
  border-radius: 5px;
  width: 50%;
  height: auto;
  text-align: center;
}
.price h3 {
  color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
}
.whatsappButtom {
  color: #000;
}
.fbButton {
  float: right;
}
#leyendaPrecios {
  float: right;
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-bottom: 10px;
}
@media screen and (max-width: 415px) {
  .agentPhoto .email {
    display: none !important;
  }
  .show-more {
    max-height: 2000px !important;
    height: auto !important;
    overflow: initial !important;
    position: relative;
    transition: max-height 1s;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ef4b4a !important;
  border-color: #ef4b4a !important;
}
.text-success {
  color: #6f6f6f !important;
  font-size: 25px;
}

.for-ul {
  column-count: 2 !important;
}

.banner {
  display: block;
  margin-top: 20px !important;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.banners {
  float: left;
}

.banner-button {
  display: block;
  color: #373737;
  width: auto;
  border: 1px solid #ef4b4a;
  border-radius: 20px;
  padding: 7px;
  margin: 5px;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.banner-button:hover {
  border: 1px solid #ef4b4a;
  background: #ef4b4a;
  color: #ffff;
}

.buttons-content {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
}

.fa-check-square {
    color: #ef4b4a;
}

.dev-specs{
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: .1em;
}

.devs-list{
  font-size: 15px;
}
.devs-ul{
  list-style: none;
  padding: 0;
}

i{
  color: #ef4b4a;
}

.color-page{
  color: #ef4b4a;
}


h4{
  font-size: 20px;
}

.h2-widget{
  text-transform: capitalize;
  font-size: 18px;
}

.grey{
  color: #373737;
}
/* 

.go-masterplan-button{
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px;
  cursor: pointer;
  background-color: #ef4b4a !important;
  white-space: pre-wrap;
}
.go-masterplan-button:hover{
  background-color: #ef4b4a !important;
} */

.image-master-plan img{
  width: 100%;
  cursor: zoom-in;
} 

.img{
  width: 100px;
  }
</style>
