<template>
  <!-- Row With Forms -->
  <section class="ftco-search">
    <div class="container">
      <div class="row">
        <div class="col-md-12 search-wrap">
          <h2 class="heading h5 d-flex align-items-center pr-4 up-behind">
            <i class="fa fa-search mr-3"></i> {{$t('layout.header.search_form.title')}}
          </h2>

          <form v-on:submit.prevent="searchProps" class="search-property">
            <div class="row justify-content-center">
              <!-- KEYWORD -->
              <div
                v-if="!search.searchDevelopments"
                class="col-md-3 align-items-end"
              >
                <div class="form-group">
                  <label for="list-types">KEYWORD</label>
                  <div class="form-field">
                    <div class="icon">
                      <span class="icon-pencil"></span>
                    </div>
                    <input
                      v-model="search.keyword"
                      type="text"
                      class="form-control"
                      :placeholder="
                        $t('layout.header.search_form.keyword_input')
                      "
                    />
                  </div>
                </div>
              </div>

              <!-- PRICE MIN -->
              <div class="col-md-3 align-items-end">
                <div class="form-group">
                  <label for="list-types">{{
                    $t("layout.header.search_form.min_price_input")
                  }}</label>
                  <div class="form-field">
                    <div class="icon">
                      <span class="icon-usd"></span>
                    </div>
                    <money
                      type="text"
                      class="form-control d-block rounded-0"
                      step="any"
                      name="minprice"
                      v-model="search.minPrice"
                      v-bind="moneyMin"
                    ></money>
                  </div>
                </div>
              </div>

              <!-- PRICE MAX -->
              <div class="col-md-3 align-items-end">
                <div class="form-group">
                  <label for="list-types">{{
                    $t("layout.header.search_form.max_price_input")
                  }}</label>
                  <div class="form-field">
                    <div class="icon">
                      <span class="icon-usd"></span>
                    </div>
                    <money
                      type="text"
                      class="form-control d-block rounded-0"
                      step="any"
                      name="maxprice"
                      v-model="search.maxPrice"
                      v-bind="moneyMax"
                    ></money>
                  </div>
                </div>
              </div>

              <!-- PROPERTY TYPE -->
              <div class="col-md-3 align-items-end">
                <div class="form-group">
                  <label for="list-types">{{
                    $t(
                      `layout.header.search_form.${
                        !search.searchDevelopments
                          ? "property_type_input"
                          : "development_type_input"
                      }`
                    )
                  }}</label>
                  <div class="form-field">
                    <span class="icon icon-home"></span>
                    <select
                      name="select-type"
                      id="select-type"
                      v-model="search.type"
                      class="form-control d-block rounded-0"
                    >
                      <option selected value="">
                        {{ $t("layout.header.search_form.option_select") }}
                      </option>
                      <option
                        v-for="(type, i) in !search.searchDevelopments
                          ? input.types
                          : input.typesDevs"
                        :key="i"
                        :index="i"
                        :value="type.typepropertyid"
                      >
                        {{ type.typepropertyspa }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- OPERATION -->
              <div
                v-if="!search.searchDevelopments"
                class="col-md-3 align-items-end"
              >
                <div class="form-group">
                  <label for="list-types">{{
                    $t("layout.header.search_form.operation_input")
                  }}</label>
                  <div class="form-field">
                    <span class="icon icon-check-square"></span>
                    <select
                      name="select-city"
                      id="select-city"
                      v-model="search.operation"
                      class="form-control d-block rounded-0"
                    >
                      <option selected value="">
                        {{ $t("layout.header.search_form.option_select") }}
                      </option>
                      <option value="1">
                        {{ $t("layout.header.search_form.option_sold") }}
                      </option>
                      <option value="2">
                        {{ $t("layout.header.search_form.option_rent") }}
                      </option>
                      <option value="3">
                        {{ $t("layout.header.search_form.option_vacation") }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- LOCATION -->
              <div class="col-md-3 align-items-end">
                <div class="form-group">
                  <label for="select-city">{{
                    $t("layout.header.search_form.location_input")
                  }}</label>
                  <div class="form-field">
                    <div class="icon">
                      <span class="icon-map-marker"></span>
                    </div>

                    <select
                      name="select-city"
                      id="select-city"
                      class="form-control"
                      v-model="search.city"
                    >
                      <option selected value="">
                        {{ $t("layout.header.search_form.option_select") }}
                      </option>
                      <option
                        v-for="(city, i) in !search.searchDevelopments
                          ? input.cities
                          : input.citiesDevs"
                        :key="i"
                        :index="i"
                        :value="city.city"
                      >
                        {{ city.city }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- OPERATION -->
<!-- 
              <div class="col-md-3 text-center">
                <div class="form-group">
                  <label class="label-switch"
                    >{{ $t("layout.header.search_form.type_search") }}:
                  </label>
                  <label class="switch span mt-2">
                    <input
                      v-model="search.searchDevelopments"
                      @click="changeForm"
                      type="checkbox"
                    />
                    <span class="slider"></span>
                  </label>
                </div>
              </div> -->

              <!-- BUTTON -->
              <div class="col-md-3 align-items-end">
                <div class="form-group">
                  <div class="form-field">
                    <label for=""></label>
                    <button type="submit" class="form-control btn btn-primary">
                      {{ $t("layout.header.search_form.button") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
export default {
  components: {
    Money,
  },
  data() {
    return {
      search: {
        operation: "",
        type: "",
        typeDevs: "",
        city: "",
        keyword: "",
        minPrice: "",
        maxPrice: "",
        urbanization: "",
        beds: "",
        baths: "",
        parking: "",
        searchDevelopments: false,
      },
      searching: false,
      moneyMin: {
        thousand: ",",
        prefix: "",
        precision: 0,
        masked: true,
      },
      moneyMax: {
        thousand: ",",
        prefix: "",
        precision: 0,
        masked: true,
      },
      advanced: false,
    };
  },
  computed: {
    ...mapGetters({
      input: "_getInputs",
    }),
  },
  methods: {
    searchProps() {
      this.searching = true;
      let searchForm = this.search;
      this.$store.dispatch("getResults", searchForm);

      if (!this.search.searchDevelopments) {
        if (this.$route.name != "Results")
          this.$router.push({ name: "Results" });
      } else {
        if (this.$route.name != "ResultsDevs")
          this.$router.push({ name: "ResultsDevs" });
      }

      this.searching = false;
    },
    advancedChange() {
      let temp = this.advanced;
      this.advanced = !temp;
    },
    changeForm() {
      for (let i in this.search) {
        if (i != "searchDevelopments") this.search[i] = "";
      }
    },
  },
  created() {
    this.$store.dispatch("getInputs");
    console.log(this.$route.name);
    if (
      this.$route.name == "Developments" ||
      this.$route.name == "ResultsDevs"
    ) {
      this.search.searchDevelopments = true;
    }
  },
};
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.label-switch {
  margin-right: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ef4b4a;
}

input:focus + .slider {
  box-shadow: 0 0 1px#ef4b4a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.label-switch {
  margin-right: 30px;
}

@media only screen and (max-width: 1200px) {
  .btn_search #search {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 300px) {
  .custom-dropdown {
    width: 100%;
  }
  .custom-dropdown select {
    width: 100%;
  }
  .big {
    font-size: 13px;
  }
}

.up-behind{
  margin-top: 20px;
}
</style>